import {
  type App,
  DeriveTableProps,
  type Plugin,
  Route,
  Table,
  TableDefinition,
  View,
} from "@pimo/pimo-app-builder";
import {
  ArtefactsProgressCard,
  CategoriesWithValuesInBadgesCard,
  ChipCell,
  CircularProgressCell,
  DateCell,
  DonutVerticalChartCard,
  GridLayoutProps,
  IconCell,
  IdentifierBadge,
  LargeTextCard,
  ProgressBarGaugeCard,
  RowLayout,
  SectionHeader,
  SectionHeaderWithMultipleBadges,
  STATUS_TYPE,
  StatusCard,
  StatusIndicatorCell,
  TabLayout,
  TabsLayoutProps,
  TeamCard,
  TextCardCell,
} from "@pimo/pimo-components";
import {
  ActivityAttributes,
  ActivityStatus,
  Dimension,
  DIMENSIONS,
  EditDialogData,
  Maturity,
  OEProjectAttributes,
} from "dlp-types";
import {
  appendCurrency,
  areAllActivitiesCompleted,
  capitalize,
  DLP_COLORS,
  extractActivityId,
  formatDate,
  formatDateTime,
  getChipAndTextColorForPriority,
  getColorAndIconForReportingDate,
  getFileIconFromFileType,
  getPriorityFromYear,
  getStatusColorForActivityStatus,
  getTextAndColorForMaturityCardBasedOnMaturityLevel,
  getTextColorAndIconForActivitsStatusDate,
  isActivityOverdue,
  transformActivitiesToActivitiesGroupedBySubdimesion,
} from "dlp-utils";

import { DLPAppState } from "../app";
import { ActvitiesExtraDetailsDialog } from "../components/activites-extra-details-dialog/activities-extra-details-dialog";
import { ReportAttachmentsTitleCard } from "../components/attachments-title-card/attachments-title-card";
import { CombinedMaturityActivityStatusCard } from "../components/combined-activity-maturity-status-card/combined-activity-maturity-status-card";
import { EditDashboardDialog } from "../components/edit-dashboard/edit-dashboard";
import { EditDialog } from "../components/edit-dialog/edit-dialog";
import { ReportTitleCard } from "../components/report-title-card/report-title-card";
import { StatusOfActivitiesTableContainer } from "../components/status-of-activities-table/status-of-activites-table-container";
import { latestCompletionDate } from "../constants";
import { STRAPI_URL } from "../env";
import { canUserAccessEditPage } from "../helpers/can-user-access-edit-page";
import {
  fetchAllAttachmentsForProject,
  fetchOEProject,
  fetchProgram,
  updateOeProjectAndRelatedActivities,
  uploadAttachments,
} from "../helpers/dlp-fetch-helper";
import { isUserAdmin } from "../helpers/is-user-admin";
import { theme } from "../theme";

type Viewname =
  | "dashboard"
  | "awareness"
  | "governance"
  | "technology"
  | "processes"
  | "attachments";

export interface EditPagesAppState {
  editDialogData?: EditDialogData;
}

export class ReportPlugin
  implements Plugin<DLPAppState, DLPAppState, "reportId" | "viewname">
{
  route?: Route<"reportId" | "viewname", DLPAppState>;
  private reportView?: View<DLPAppState, TabsLayoutProps>;
  private app?: App<DLPAppState>;

  onRegister(app: App<DLPAppState>): void {
    this.app = app;
    const tabs: {
      name: string;
      viewname: Viewname;
      path: `/reports/:reportId/${Viewname}`;
    }[] = [
      {
        name: "Dashboard",
        viewname: "dashboard",
        path: "/reports/:reportId/dashboard",
      },
      {
        name: "Governance",
        viewname: "governance",
        path: "/reports/:reportId/governance",
      },
      {
        name: "Awareness",
        viewname: "awareness",
        path: "/reports/:reportId/awareness",
      },
      {
        name: "Processes",
        viewname: "processes",
        path: "/reports/:reportId/processes",
      },
      {
        name: "Technology",
        viewname: "technology",
        path: "/reports/:reportId/technology",
      },
      {
        name: "Attachments",
        viewname: "attachments",
        path: "/reports/:reportId/attachments",
      },
    ];

    this.reportView = app.createView({
      name: "Reports",
      layout: new TabLayout(tabs, 1, [
        {
          icon: "pdf.svg",
          text: "PDF Export",
          onClick: () => {
            window.requestIdleCallback(() => window.print());
          },
        },
        {
          icon: "excel.svg",
          text: "Excel Export",
          onClick: () => {
            if (app.getAppState().currentOEProject?.id === undefined) {
              return;
            }
            const projectId = app.getAppState().currentOEProject?.id;
            /** linter still complains */
            window.open(`${STRAPI_URL}/api/export/${projectId ?? 1}`);
          },
        },
      ]),
    });

    for (const { viewname } of tabs) {
      if (viewname !== "dashboard" && viewname !== "attachments") {
        this.buildDimensionReportingView({ viewname });
      }
    }
    const view = this.reportView;
    this.buildDashboardView(view);
    this.buildAttachmentsView();
  }

  private buildAttachmentsView() {
    if (!this.reportView || !this.app) {
      return;
    }

    const titleComponent = this.reportView.addComponent({
      component: ReportAttachmentsTitleCard,
      layoutProps: { viewname: "attachments", xs: 12 },
    });

    titleComponent.mapState((state) => {
      const { reportingDate = "" } = state.program ?? {};
      const {
        endDate = "",
        startDate = "",
        createdAt = "",
        updatedAt = "",
      } = state.currentOEProject ?? {};

      const title = "Attachments";

      return {
        endDate,
        title,
        startDate,
        reportingDateIndicator: getColorAndIconForReportingDate(
          updatedAt ?? createdAt,
          reportingDate
        ),
        showUploadButton: canUserAccessEditPage(
          state.userProfile,
          state.currentOEProject
        ),
      };
    });

    titleComponent.on("upload-button:click", async ({ payload }) => {
      if (!payload) {
        return;
      }
      await uploadAttachments(
        payload,
        (this.app?.getAppState().currentOEProject?.id ?? 0).toString()
      );
      const attachments = await fetchAllAttachmentsForProject(
        (this.app?.getAppState().currentOEProject?.id ?? 0).toString()
      );
      this?.app?.setAppState({ ...this?.app?.getAppState(), attachments });
    });

    const tableDefinition: TableDefinition = [
      { component: IconCell },
      { component: TextCardCell },
      { component: TextCardCell },
      { component: DateCell },
    ] as const;
    const table = new Table(tableDefinition, "report");
    const attachmentsTable = this.reportView.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        viewname: "attachments",
        xs: 12,
      },
    });

    attachmentsTable.mapState((state) => {
      const attachments = state?.attachments ?? [];

      return {
        data:
          attachments.map(
            (item): DeriveTableProps<typeof table>["data"][number] => {
              return {
                rowProps: {
                  cardProps: { sx: { cursor: "pointer" } },
                  onClick: () => {
                    window.open(`${STRAPI_URL}${item.url}`, "_blank");
                  },
                },
                columnProps: [
                  {
                    value: getFileIconFromFileType(item.ext),
                  },
                  {
                    body: item.name,
                    bodyProps: {
                      sx: {
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        minWidth: 400,
                        textDecoration: "underline",
                      },
                    },
                  },
                  {
                    body: item.caption,
                    bodyProps: { sx: { minWidth: 200, maxWidth: 200 } },
                  },

                  {
                    date: formatDate(item.createdAt),
                    icon: "calendar-outlined.svg",
                    cardProps: {
                      sx: {
                        color: "inherit",
                        height: "auto",
                      },
                    },
                  },
                ],
              };
            }
          ) ?? [],
        tableHeaderEntries: ["Type", "Name", "Author", "Upload Date"],
      };
    });
  }

  buildDashboardView(
    view: View<DLPAppState, GridLayoutProps | TabsLayoutProps>,
    id?: number
  ) {
    if (!this.app || !view) {
      return;
    }

    const titleComponent = view.addComponent({
      component: ReportTitleCard,
      layoutProps: { viewname: "dashboard", xs: 12 },
    });

    titleComponent.mapState((state) => {
      const { reportingDate = "" } = state.program ?? {};
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      const {
        name = "",
        oeEarliestUpdatedAt = "",
        oeEarliestUpdatedBy,
      } = project;

      const formattedReportingDate = formatDate(reportingDate);
      const title = [
        "Dashboard",
        [name, formattedReportingDate && `(as of ${formattedReportingDate})`]
          .filter(Boolean)
          .join(" "),
      ].join(" - ");
      const onHoverText = oeEarliestUpdatedAt
        ? `as of ${formatDateTime(oeEarliestUpdatedAt)} ${
            oeEarliestUpdatedBy ? `by ${oeEarliestUpdatedBy}` : ""
          }`
        : "";

      return {
        endDate: formatDate(new Date(new Date().getFullYear(), 11, 31)),
        title,
        showEditButton: canUserAccessEditPage(
          state.userProfile,
          state.currentOEProject
        ),
        members:
          project.team?.map(({ person, role }) => ({
            name: person?.name ?? "",
            position: role?.name ?? "",
          })) ?? [],
        reportingDateIndicator: {
          ...getColorAndIconForReportingDate(
            oeEarliestUpdatedAt,
            reportingDate,
            areAllActivitiesCompleted(state.currentOEProject?.activities ?? [])
          ),
          onHoverText,
          color: "white",
        },
      };
    });
    titleComponent.on("edit-button:click", () => {
      this.app?.navigate("edit-dashboard");
    });
    const overallMaturityTitle = view.addComponent({
      component: SectionHeaderWithMultipleBadges,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    overallMaturityTitle.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      return {
        badges: [
          {
            title: `Overall Maturity:`,

            badgeTextContent: ` ${
              project._calculated?.maturity as unknown as Maturity
            } `,
            ...getTextAndColorForMaturityCardBasedOnMaturityLevel(
              (project._calculated?.maturity as unknown as Maturity) ?? 0
            ).colorScheme,
          },
          {
            title: `Target Date Maturity Level 1`,

            badgeTextContent: latestCompletionDate.priority1,
            textColor: "black",
            chipColor: DLP_COLORS.babyBlue,
            textProps: { sx: { fontWeight: 400 } },
          },
          {
            title: `Target Date Maturity Level 2`,

            badgeTextContent: latestCompletionDate.priority2,
            textColor: "black",
            chipColor: DLP_COLORS.babyBlue,
            textProps: { sx: { fontWeight: 400 } },
          },
          {
            title: `Target Date Maturity Level 3`,

            badgeTextContent: latestCompletionDate.priority3,
            textColor: "black",
            chipColor: DLP_COLORS.babyBlue,
            textProps: { sx: { fontWeight: 400 } },
          },
        ],

        sx: {
          "@media print": {
            pageBreakBefore: "avoid",
          },
        },
        chipColor:
          getTextAndColorForMaturityCardBasedOnMaturityLevel(
            project._calculated?.maturity as unknown as Maturity
          )?.colorScheme.chipColor ?? "",
        textColor:
          getTextAndColorForMaturityCardBasedOnMaturityLevel(
            project._calculated?.maturity as unknown as Maturity
          )?.colorScheme.textColor ?? "",
      };
    });

    const combinedActivityStatusCard = view.addComponent({
      component: CombinedMaturityActivityStatusCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 3,
      },
    });

    combinedActivityStatusCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      const numberOfOverdueActivities = project.numberOfOverdueActivities ?? 0;

      return {
        numberCardProps: {
          iconSrc: "calendar.svg",
          number: numberOfOverdueActivities,
          title: "Activities Overdue",
        },
        lineGaugeCardProps: {
          title: "Maturity Level",
          scale: [0, 1, 2, 3],
          startDate: "",
          showStartDate: false,
          showEndDate: false,
          endDate: "",
          entries: [
            {
              isTitle: true,
              label: "",
              value: project._calculated?.maturity ?? 0,
              percentage: ((project._calculated?.maturity ?? 0) * 100) / 3,
            },
          ],
        },
      };
    });

    const maturityOneDonutChartCard = view.addComponent({
      component: DonutVerticalChartCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 3,
      },
    });

    maturityOneDonutChartCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      const statusesToDisplay: ActivityStatus[] = [
        "not applicable",
        "not started",
        "at risk",
        "on track",
        "completed",
      ];
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `Activities Maturity 1 (total: ${
          project.activities?.filter((a) => a.priority === "maturity 1").length
        })`,
        series: statusesToDisplay.map(
          (status) =>
            project._calculated?.activityStatusPerMaturity?.["maturity 1"][
              status
            ] ?? 0
        ),
        backgroundColor: [
          "#eeeeee",
          "#a3d4eb",
          "#FAB600",
          "#00C853",
          "#003781",
        ],
        fallbackText: "No activities of maturity level 1 specified.",
        legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
        legendPosition: "left",
        labels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipEnabled: true,
      };
    });
    const maturityTwoDonutChartCard = view.addComponent({
      component: DonutVerticalChartCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 3,
      },
    });
    maturityTwoDonutChartCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      const statusesToDisplay: ActivityStatus[] = [
        "not applicable",
        "not started",
        "at risk",
        "on track",
        "completed",
      ];
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `Activities Maturity 2 (total: ${
          project.activities?.filter((a) => a.priority === "maturity 2").length
        })`,
        series: statusesToDisplay.map(
          (status) =>
            project._calculated?.activityStatusPerMaturity?.["maturity 2"][
              status
            ] ?? 0
        ),
        backgroundColor: [
          "#eeeeee",
          "#a3d4eb",
          "#FAB600",
          "#00C853",
          "#003781",
        ],
        fallbackText: "No activities of maturity level 2 specified.",
        legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
        legendPosition: "left",
        labels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipEnabled: true,
      };
    });

    const maturityThreeDonutChartCard = view.addComponent({
      component: DonutVerticalChartCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 3,
      },
    });

    maturityThreeDonutChartCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      const statusesToDisplay: ActivityStatus[] = [
        "not applicable",
        "not started",
        "at risk",
        "on track",
        "completed",
      ];
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `Activities Maturity 3 (total: ${
          project.activities?.filter((a) => a.priority === "maturity 3").length
        })`,
        series: statusesToDisplay.map(
          (status) =>
            project._calculated?.activityStatusPerMaturity?.["maturity 3"][
              status
            ] ?? 0
        ),
        backgroundColor: [
          "#eeeeee",
          "#a3d4eb",
          "#FAB600",
          "#00C853",
          "#003781",
        ],
        fallbackText: "No activities of maturity level 3 specified.",
        legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
        legendPosition: "left",
        labels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipEnabled: true,
      };
    });

    const keyInformation = view.addComponent({
      component: LargeTextCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    keyInformation.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      return {
        cardTitle: "Management Summary",
        textContent: project.executiveComment ?? "",
        maxHeight: 310,
      };
    });

    for (const dimension of DIMENSIONS) {
      const gaugeCard = view.addComponent({
        component: ProgressBarGaugeCard,
        layoutProps: {
          viewname: "dashboard",
          xs: 3,
        },
      });

      gaugeCard.mapState((state) => {
        const project =
          state.oeProjects?.find((project) => project.id === id) ??
          state.currentOEProject ??
          ({} as OEProjectAttributes);

        const completionDate =
          project?.completionDatePerDimension?.[dimension]?.completionDate;

        return {
          title: `Maturity - ${dimension}`,
          startDate: "",
          showStartDate: false,
          showEndDate: false,
          endDate: completionDate ? formatDate(completionDate) : "n/a",
          entries: [
            {
              label: "Maturity",
              isTitle: true,
              precision: 1,
              percentage:
                (project._calculated
                  ?.maturityLevelPerDimensionAndPriorityOnOELevel?.[
                  dimension
                ] ?? 0) * 50,
              value:
                project._calculated
                  ?.maturityLevelPerDimensionAndPriorityOnOELevel?.[
                  dimension
                ] ?? 0,
            },
            ...(project?._calculated?.completedActivitiesPerOEPerDimensionAndPerPriority?.perDimension?.[
              dimension
            ]?.perPriority?.map((entry) => ({
              label: capitalize(
                entry.priority === "maturity 1"
                  ? "Maturity Level 1"
                  : entry.priority === "maturity 2"
                    ? "Maturity Level 2"
                    : "Maturity Level 3"
              ),

              percentage: entry.completionProgress ?? 0,
              value: entry.completionProgress ?? 0,
              numerator: entry.completedActivities ?? 0,
              denumerator: entry.totalNumberOfActivities ?? 0,
            })) ?? []),
          ],
          scale: [0, 1, 2],
        };
      });
    }

    const overallActivitiesTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    overallActivitiesTitle.mapState(() => {
      return {
        title: `Status of Activities Maturity Level 1`,
        textColor: "#fff",
      };
    });

    for (const dimension of DIMENSIONS) {
      const verticalDonutChartCard = view.addComponent({
        component: DonutVerticalChartCard,
        layoutProps: {
          viewname: "dashboard",
          xs: 3,
        },
      });

      verticalDonutChartCard.mapState((state) => {
        const project =
          state.oeProjects?.find((project) => project.id === id) ??
          state.currentOEProject ??
          ({} as OEProjectAttributes);

        const statusesToDisplay: ActivityStatus[] = [
          "not applicable",
          "not started",
          "at risk",
          "on track",
          "completed",
        ];

        return {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: `${dimension} (total: ${
            state.currentOEProject?._calculated?.completedActivitiesPerOEPerDimensionAndPerPriority?.perDimension[
              dimension
            ].perPriority.find((p) => p.priority === "maturity 1")
              ?.totalNumberOfActivities ?? 0
          })`,
          series: statusesToDisplay.map(
            (status) =>
              project._calculated?.activityPerStatusPerOEAndDimension?.[
                dimension
              ].perMaturity["maturity 1"][status] ?? 0
          ),
          backgroundColor: [
            "#eeeeee",
            "#a3d4eb",
            "#FAB600",
            "#00C853",
            "#003781",
          ],
          fallbackText:
            "No activities of maturity level 1 for this dimension specified.",
          legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        };
      });
    }
    const overallActivitiesM2Title = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    overallActivitiesM2Title.mapState(() => {
      return {
        title: `Status of Activities Maturity Level 2`,
        textColor: "#fff",
      };
    });

    for (const dimension of DIMENSIONS) {
      const verticalDonutChartCard = view.addComponent({
        component: DonutVerticalChartCard,
        layoutProps: {
          viewname: "dashboard",
          xs: 3,
        },
      });

      verticalDonutChartCard.mapState((state) => {
        const project =
          state.oeProjects?.find((project) => project.id === id) ??
          state.currentOEProject ??
          ({} as OEProjectAttributes);

        const statusesToDisplay: ActivityStatus[] = [
          "not applicable",
          "not started",
          "at risk",
          "on track",
          "completed",
        ];
        return {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: `${dimension} (total: ${
            state.currentOEProject?._calculated?.completedActivitiesPerOEPerDimensionAndPerPriority?.perDimension[
              dimension
            ].perPriority.find((p) => p.priority === "maturity 2")
              ?.totalNumberOfActivities ?? 0
          })`,
          fallbackText:
            "No activities of maturity level 2 for this dimension specified.",
          series: statusesToDisplay.map(
            (status) =>
              project._calculated?.activityPerStatusPerOEAndDimension?.[
                dimension
              ].perMaturity["maturity 2"][status] ?? 0
          ),
          backgroundColor: [
            "#eeeeee",
            "#a3d4eb",
            "#FAB600",
            "#00C853",
            "#003781",
          ],
          legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        };
      });
    }

    const overallActivitiesM3Title = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    overallActivitiesM3Title.mapState(() => {
      return {
        title: `Status of Activities Maturity Level 3`,
        textColor: "#fff",
      };
    });

    for (const dimension of DIMENSIONS) {
      const verticalDonutChartCard = view.addComponent({
        component: DonutVerticalChartCard,
        layoutProps: {
          viewname: "dashboard",
          xs: 3,
        },
      });

      verticalDonutChartCard.mapState((state) => {
        const project =
          state.oeProjects?.find((project) => project.id === id) ??
          state.currentOEProject ??
          ({} as OEProjectAttributes);

        const statusesToDisplay: ActivityStatus[] = [
          "not applicable",
          "not started",
          "at risk",
          "on track",
          "completed",
        ];
        return {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: `${dimension} (total: ${
            state.currentOEProject?._calculated?.completedActivitiesPerOEPerDimensionAndPerPriority?.perDimension[
              dimension
            ].perPriority.find((p) => p.priority === "maturity 3")
              ?.totalNumberOfActivities ?? 0
          })`,
          fallbackText:
            "No activities of maturity level 3 for this dimension specified.",
          series: statusesToDisplay.map(
            (status) =>
              project._calculated?.activityPerStatusPerOEAndDimension?.[
                dimension
              ].perMaturity["maturity 3"][status] ?? 0
          ),
          backgroundColor: [
            "#eeeeee",
            "#a3d4eb",
            "#FAB600",
            "#00C853",
            "#003781",
          ],
          legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        };
      });
    }

    const projectInformationTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        viewname: "dashboard",
        xs: 12,
      },
    });

    projectInformationTitle.mapState(() => ({ title: "General Information" }));

    const teamCard = view.addComponent({
      component: TeamCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 6,
      },
    });

    teamCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      const { team } = project;

      return {
        team: {
          teamMembers:
            team
              ?.filter((member) => member.role?.name && member.person?.name)
              .map((teamMember) => {
                const { name } = teamMember?.person ?? {};
                const { name: roleName } = teamMember?.role ?? {};

                return {
                  fullName: name ?? "n/a",
                  role: roleName ?? "n/a",
                  chipColor: DLP_COLORS.babyBlue,
                  textColor: "black",
                };
              }) ?? [],
        },
        title: "DLP Functions",
      };
    });
    const descriptionCard = view.addComponent({
      component: LargeTextCard,
      layoutProps: {
        viewname: "dashboard",
        xs: 6,
      },
    });

    descriptionCard.mapState((state) => {
      const project =
        state.oeProjects?.find((project) => project.id === id) ??
        state.currentOEProject ??
        ({} as OEProjectAttributes);

      return {
        cardTitle: "Description of DLP Activity Status and Activities Overdue",
        textContent: project.description ?? "",
      };
    });
  }

  private buildStatusOfActivitiesTable(viewname: Viewname) {
    if (!this.reportView || !this.app) {
      return;
    }

    const popup = this.reportView.addComponent({
      component: ActvitiesExtraDetailsDialog,
      layoutProps: { viewname, xs: 12 },
    });

    popup.mapState((state) => {
      const arrayEntries = [];

      switch (state.reportPluginPopupContent?.solutionType) {
        case "Local":
          arrayEntries.push({
            label: "Local Solution",
            value: state.reportPluginPopupContent?.localSolutionName ?? "n/a",
          });
          break;

        case "Target":
          arrayEntries.push({
            label: "Target Solution",
            value: state.reportPluginPopupContent?.targetSolution ?? "n/a",
          });
          break;

        case "Local & Target":
          arrayEntries.push(
            {
              label: "Target Solution",
              value: state.reportPluginPopupContent?.targetSolution ?? "n/a",
            },
            {
              label: "Local Solution",
              value: state.reportPluginPopupContent?.localSolutionName ?? "n/a",
            }
          );
          break;

        default:
          break;
      }

      return {
        open: state?.reportPluginPopupOpen ?? false,
        sectionsToDisplay: [
          {
            title: "Activity",
            text: state.reportPluginPopupContent?.description ?? "",
          },
          {
            title: "Objective",
            text: state.reportPluginPopupContent?.objective ?? "n/a",
          },
          {
            title: "Comment on Status",
            text: state.reportPluginPopupContent?.comment ?? "n/a",
          },
          ...(state.reportPluginPopupContent?.hasRisk
            ? [
                {
                  title: "Comment on Risk",
                  text: state.reportPluginPopupContent?.riskComment ?? "",
                },
              ]
            : []),
        ],
        solutionReason:
          state.reportPluginPopupContent?.dimension === "Technology"
            ? {
                title: "Solution Reason",
                text: state.reportPluginPopupContent?.solutionReason ?? "n/a",
              }
            : undefined,
        entries: [
          {
            label: "Project Plan",
            value: state.reportPluginPopupContent?.projectPlan ?? "n/a",
          },
          {
            label: "Allocated Budget",
            value:
              appendCurrency(
                (
                  state.reportPluginPopupContent?.allocatedBudget ?? ""
                ).toLocaleString(),
                "€",
                "0 €"
              ) ?? "n/a",
          },
          ...(state.reportPluginPopupContent?.dimension === "Technology"
            ? [
                {
                  label: "Solution Type",
                  value: state.reportPluginPopupContent?.solutionType ?? "n/a",
                },
              ]
            : []),
          ...(state.reportPluginPopupContent?.dimension === "Technology"
            ? arrayEntries
            : []),
        ],

        cardTitle: `Details on ${
          state.reportPluginPopupContent?.activityId ?? ""
        }`,
      };
    });
    const onPopupOpen = (payload: ActivityAttributes) => {
      const state = this.app?.getAppState();
      if (!payload || !state) {
        return;
      }

      this.app?.setAppState({
        ...state,
        reportPluginPopupContent: payload,
        reportPluginPopupOpen: true,
      });
    };

    popup.on("popup:close", () => {
      const state = this.app?.getAppState();
      if (!state) {
        return;
      }

      this.app?.setAppState({
        ...state,
        reportPluginPopupOpen: false,
      });
    });
    const dimension = this.getDimensionForViewname(viewname);
    const tableDefinition: TableDefinition = [
      { component: IdentifierBadge },
      { component: ChipCell },
      { component: TextCardCell },
      { component: StatusIndicatorCell },
      { component: CircularProgressCell },
      { component: DateCell },
      { component: DateCell },
      { component: TextCardCell },
    ] as const;
    const table = new Table(tableDefinition, "grouped-report");
    const statusOfActivitiesTable = this.reportView.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        viewname,
        xs: 12,
      },
    });

    statusOfActivitiesTable.mapState((state) => {
      const items = (state.currentOEProject?.activities ?? [])
        .sort((a, b) => (a.activityId ?? "")?.localeCompare(b.activityId ?? ""))
        .filter((item) => item?.dimension === dimension);
      return {
        container: StatusOfActivitiesTableContainer,
        data: items.map(
          (item): DeriveTableProps<typeof table>["data"][number] => {
            const {
              activityId,
              description,
              completionDate,
              progress,
              status,
              progressFromIam,
              statusFromIam,
              actualCompletionDate = "",
            } = item ?? {};

            const formattedActualCompletionDate =
              formatDate(actualCompletionDate);

            const priorityValues =
              state.currentOEProject?._calculated?.completedActivitiesPerOEPerDimensionAndPerPriority?.perDimension?.[
                dimension
              ].perPriority.find((entry) => entry.priority === item.priority);
            const overdue = isActivityOverdue(item);
            const { icon, color, textOnHover } =
              getTextColorAndIconForActivitsStatusDate(
                overdue,
                completionDate,
                getPriorityFromYear(new Date().getFullYear()) === "maturity 1"
                  ? latestCompletionDate.priority1
                  : latestCompletionDate.priority2
              );
            return {
              rowProps: {
                group: `${capitalize(item.priority ?? "")}: ${
                  priorityValues?.completedActivities ?? 0
                } / ${priorityValues?.totalNumberOfActivities ?? 0} (${
                  priorityValues?.completionProgress ?? 0
                } %)`,
                chip: {
                  text: "#000",
                  color: DLP_COLORS.babyBlue,
                },
                onClick: () => {
                  onPopupOpen(item);
                },
                cardProps: { sx: { cursor: "pointer" } },
              },
              columnProps: [
                {
                  chipColor: DLP_COLORS.cyanBlue,
                  children: extractActivityId(activityId),
                  chipWidth: 75,
                },
                {
                  body: capitalize(item.priority ?? ""),
                  chipProps: {
                    sx: {
                      borderRadius: "12px",
                      backgroundColor: getChipAndTextColorForPriority(
                        item.priority
                      ).colorScheme.chipColor,
                      color: getChipAndTextColorForPriority(item.priority)
                        .colorScheme.textColor,
                      maxWidth: 120,
                      minWidth: 120,
                    },
                  },
                },
                {
                  body: description,

                  bodyProps: {
                    sx: {
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      minWidth: 300,
                    },
                  },
                },

                {
                  body: (statusFromIam ?? status) || "not started",
                  textOnHover: formattedActualCompletionDate
                    ? `as of ${formattedActualCompletionDate}`
                    : "",
                  status: getStatusColorForActivityStatus(
                    (statusFromIam ?? status) || "not started"
                  ),
                  sx: {
                    borderRadius:
                      (statusFromIam ?? status) === "completed" ? null : "50%",
                  },
                },
                {
                  value: progressFromIam ?? progress ?? 0,
                },
                {
                  date: formatDate(
                    item.priority === "maturity 1"
                      ? new Date(2023, 11, 31)
                      : new Date(2024, 11, 31)
                  ),
                  icon: "calendar-outlined.svg", //icon,
                  cardProps: {
                    sx: {
                      color: theme.palette.primary.main, // color,
                      height: "auto",
                    },
                  },
                },
                {
                  date: formatDate(completionDate ?? ""),
                  icon: icon, //icon,
                  cardProps: {
                    sx: {
                      color: color, // color,
                      height: "auto",
                    },
                  },
                  textOnHover: textOnHover, // textOnHover,
                },
                { body: item.hasRisk ? item.riskId : "" },
              ],
            };
          }
        ),
        tableHeaderEntries: [
          "Id",
          "Maturity",
          "Activity",

          "Status",
          "Progress",
          "Target Completion Date",
          "Planned Completion Date",
          "Risk",
        ],
      };
    });
  }

  private buildDimensionReportingView({ viewname }: { viewname: Viewname }) {
    if (!this.reportView || !this.app) {
      return;
    }

    this.buildDimensionTitleCard(viewname);
    this.buildActivitiesCard(viewname);
    this.buildActivitiesInSubdimensionsCard(viewname);
    this.buildDateCardsInSubdimensionsCard(viewname);
    this.buildStatusCard(viewname);
    this.buildCommentsCard(viewname);
    this.buildKeyInformationCard(viewname);
    this.buildStatusOfActivitiesTable(viewname);
    this.buildRoute();
  }

  private buildDimensionTitleCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const titleComponent = this.reportView.addComponent({
      component: ReportTitleCard,
      layoutProps: { viewname, xs: 12 },
    });

    titleComponent.mapState((state) => {
      const { reportingDate = "" } = state.program ?? {};
      const {
        name,
        team = [],

        awarenessDimensionUpdatedAt = "",
        awarenessDimensionUpdatedBy,
        govDimensionUpdatedAt = "",
        govDimensionUpdatedBy,
        techDimensionUpdatedAt = "",
        techDimensionUpdatedBy,
        processesDimensionUpdatedAt = "",
        processesDimensionUpdatedBy,
      } = state.currentOEProject ?? {};
      let dimensionUpdatedAt: string | undefined;
      let dimensionUpdatedBy: string | undefined;
      switch (viewname) {
        case "awareness":
          dimensionUpdatedAt = awarenessDimensionUpdatedAt;
          dimensionUpdatedBy = awarenessDimensionUpdatedBy;
          break;
        case "governance":
          dimensionUpdatedAt = govDimensionUpdatedAt;
          dimensionUpdatedBy = govDimensionUpdatedBy;
          break;
        case "technology":
          dimensionUpdatedAt = techDimensionUpdatedAt;
          dimensionUpdatedBy = techDimensionUpdatedBy;
          break;
        case "processes":
          dimensionUpdatedAt = processesDimensionUpdatedAt;
          dimensionUpdatedBy = processesDimensionUpdatedBy;
          break;
      }
      const formattedReportingDate = formatDate(reportingDate);
      const title = [
        ["Status Report", this.getDimensionForViewname(viewname)].join(" "),
        [name, formattedReportingDate && `(as of ${formattedReportingDate})`]
          .filter(Boolean)
          .join(" "),
      ].join(" - ");

      return {
        title,
        members:
          team?.map(({ person, role }) => ({
            name: person?.name ?? "",
            position: role?.name ?? "",
          })) ?? [],
        showEditButton: canUserAccessEditPage(
          state.userProfile,
          state.currentOEProject
        ),
        reportingDateIndicator: {
          ...getColorAndIconForReportingDate(
            dimensionUpdatedAt ?? "",
            reportingDate,
            areAllActivitiesCompleted(
              state.currentOEProject?.activities.filter(
                (activity) =>
                  activity.dimension === this.getDimensionForViewname(viewname)
              ) ?? []
            )
          ),
          onHoverText: dimensionUpdatedAt
            ? `as of ${formatDateTime(dimensionUpdatedAt)} ${
                dimensionUpdatedBy ? `by ${dimensionUpdatedBy}` : ""
              }`
            : "",
          color: "white",
        },
      };
    });
    titleComponent.on("edit-button:click", () => {
      this.app?.navigate("edit");
    });
  }

  private buildActivitiesCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const dimensionForView = this.getDimensionForViewname(viewname);

    const activities = this.reportView.addComponent({
      component: ArtefactsProgressCard,
      layoutProps: {
        viewname,
        xs: 3,
      },
    });

    activities.mapState((state) => {
      const activityProgress =
        state.currentOEProject?._calculated
          ?.activityProgressPerOEPerDimensionAndPerPriority?.perDimension?.[
          dimensionForView
        ].progress ?? 100;

      return {
        cardTitle: "Activities",
        cardIcon: "activities.svg",
        value: activityProgress,
        displayValue: `${Math.round(activityProgress)} %`,
        label: "Progress",
        color: DLP_COLORS.babyBlue,
        subtitle: "Maturity Level 1, 2 and 3",
        centerTitle: false,
      };
    });
  }
  private buildStatusCard(viewname: Viewname) {
    if (!this.reportView || !this.app) {
      return;
    }
    const dimensionStatusCard = this.reportView.addComponent({
      component: StatusCard,
      layoutProps: {
        viewname,
        xs: 3,
      },
    });
    const dimensionForView = this.getDimensionForViewname(viewname);

    dimensionStatusCard.mapState((state) => {
      const { activityPerStatusPerOEAndDimension } =
        state.currentOEProject?._calculated ?? {};

      return {
        statuses: [
          {
            status: STATUS_TYPE.WHITE,
            name: "Not applicable ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView]
                .numberOfActivitiesPerStatus["not applicable"] ?? 0,
          },
          {
            status: STATUS_TYPE.LIGHT_BLUE,
            name: "Not started ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView]
                .numberOfActivitiesPerStatus["not started"] ?? 0,
          },
          {
            status: STATUS_TYPE.YELLOW,
            name: "At risk ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView]
                .numberOfActivitiesPerStatus["at risk"] ?? 0,
          },
          {
            status: STATUS_TYPE.GREEN,
            name: "On track ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView]
                .numberOfActivitiesPerStatus["on track"] ?? 0,
          },
          {
            status: STATUS_TYPE.COMPLETED,
            name: "Completed ",
            count:
              activityPerStatusPerOEAndDimension?.[dimensionForView]
                .numberOfActivitiesPerStatus.completed ?? 0,
          },
        ],
        subStatuses: [],
        title: "Status Maturity Level 1, 2 and 3",
        isRow: false,
        cardProps: {},
      };
    });
  }

  private buildActivitiesInSubdimensionsCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const activitiesInSubDimensions = this.reportView.addComponent({
      component: CategoriesWithValuesInBadgesCard,
      layoutProps: {
        viewname,
        xs: 3,
      },
    });

    activitiesInSubDimensions.mapState((state) => {
      const completedActivitiesInDimension =
        state.currentOEProject?._calculated
          ?.completedActivitiesPerOEPerDimensionAndPerPriority?.perDimension[
          this.getDimensionForViewname(viewname)
        ];

      return {
        cardTitle: `Completed Activities (${
          completedActivitiesInDimension?.completedActivities ?? 0
        }/${completedActivitiesInDimension?.totalNumberOfActivities ?? 0})`,
        categories:
          completedActivitiesInDimension?.perPriority.map((p) => ({
            value: `${p.completedActivities ?? 0} / ${
              p.totalNumberOfActivities ?? 0
            }`,
            category: capitalize(
              p.priority === "maturity 1"
                ? "Maturity Level 1"
                : p.priority === "maturity 2"
                  ? "Maturity Level 2"
                  : "Maturity Level 3"
            ),
          })) ?? [],

        cardIcon: "checklist.svg",
        chipBackgroundColor: completedActivitiesInDimension?.perPriority.map(
          () => DLP_COLORS.babyBlue
        ),
        chipForegroundColor: "#000",
      };
    });
  }

  private buildDateCardsInSubdimensionsCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const activitiesInSubDimensions = this.reportView.addComponent({
      component: CategoriesWithValuesInBadgesCard,
      layoutProps: {
        viewname,
        xs: 3,
      },
    });

    activitiesInSubDimensions.mapState(() => {
      return {
        cardTitle: "Target Completion Dates",

        cardIcon: "checklist.svg",
        chipBackgroundColor: [
          DLP_COLORS.babyBlue,
          DLP_COLORS.babyBlue,
          DLP_COLORS.babyBlue,
        ],
        chipForegroundColor: "#000",
        categories: [
          {
            category: "Maturity Level 1",
            value: latestCompletionDate.priority1,
          },
          {
            category: "Maturity Level 2",
            value: latestCompletionDate.priority2,
          },
          {
            category: "Maturity Level 3",
            value: latestCompletionDate.priority3,
          },
        ],
      };
    });
  }

  private buildCommentsCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const comments = this.reportView.addComponent({
      component: LargeTextCard,
      layoutProps: {
        viewname,
        xs: 6,
      },
    });

    comments.mapState((state) => {
      const {
        commentAwareness,
        commentGovernance,
        commentTechnology,
        commentProcesses,
      } = state.currentOEProject ?? {};
      let comment = "";
      switch (viewname) {
        case "awareness": {
          comment = commentAwareness ?? "";
          break;
        }
        case "governance": {
          comment = commentGovernance ?? "";
          break;
        }
        case "processes": {
          comment = commentProcesses ?? "";
          break;
        }
        case "technology": {
          comment = commentTechnology ?? "";
          break;
        }
      }
      return {
        cardTitle: "Comments on DLP Status",
        textContent: comment,
      };
    });
  }

  private buildKeyInformationCard(viewname: Viewname) {
    if (!this.reportView) {
      return;
    }

    const keyInformation = this.reportView.addComponent({
      component: LargeTextCard,
      layoutProps: {
        viewname,
        xs: 6,
      },
    });

    keyInformation.mapState((state) => {
      const {
        keyRisksAwareness,
        keyRisksProcesses,
        keyRisksGovernance,
        keyRisksTech,
      } = state.currentOEProject ?? {};
      let keyRisks = "";
      switch (viewname) {
        case "awareness": {
          keyRisks = keyRisksAwareness ?? "";
          break;
        }
        case "governance": {
          keyRisks = keyRisksGovernance ?? "";
          break;
        }
        case "processes": {
          keyRisks = keyRisksProcesses ?? "";
          break;
        }
        case "technology": {
          keyRisks = keyRisksTech ?? "";
          break;
        }
      }
      return {
        cardTitle: "Key Risks",
        textContent: keyRisks,
      };
    });
  }

  private getDimensionForViewname(viewname: Viewname): Dimension {
    switch (viewname) {
      case "awareness":
        return "Awareness";
      case "governance":
        return "Governance";
      case "technology":
        return "Technology";
      case "processes":
        return "Processes";
      default:
        return "Awareness";
    }
  }

  private buildRoute() {
    if (!this.app || !this.reportView) {
      return;
    }

    this.route = this.app.createRoute<"reportId" | "viewname">({
      path: "/reports/:reportId/:viewname",
      view: this.reportView,
    });

    this.route.on("load", async ({ payload }) => {
      const params = payload?.parameters;
      if (!params?.reportId) {
        return;
      }

      const [currentOEProject, program, attachments] = await Promise.all([
        fetchOEProject(params.reportId),
        fetchProgram(),
        fetchAllAttachmentsForProject(params.reportId),
      ]);

      if (!currentOEProject || !program || !this.app) {
        return;
      }

      const state = this.app.getAppState();

      this.app.setAppState({
        ...state,
        currentOEProject,
        program,
        attachments,
        filterDialogData: {
          projectsFilter: [],
          overdueFilter: [],
          govLeadFilter: [],
          atRiskFilter: [],
          updateStatusFilter: [],
          maturityFilter: [],
          searchQueryFilter: "",
          dueYearFilter: [],
          dueQuarterFilter: [],
          regionFilter: [],
          localFilter: [],
        },
      });
    });

    const dashboardEditView = this.app.createView({
      name: "editDashboard",
      layout: new RowLayout(),
    });
    this.route.createChildRoute({
      path: "edit-dashboard",
      view: dashboardEditView,
      isOverlayChild: true,
      isVisible: true,
    });
    const editDashboardDialog = dashboardEditView.addComponent({
      component: EditDashboardDialog,
      layoutProps: { row: 2 },
    });
    editDashboardDialog.mapState((state) => ({
      title: "Edit Executive Summary",
      data: {
        ...(state.currentOEProject ?? {
          id: 0,
          name: "",
          cimId: "",
          projectId: "",
          team: [],
          activities: [],
        }),
        dimension: "dashboard",
        commentOverallStatus: "",
        activitiesGroupedBySubdimension: [],
      },
    }));

    editDashboardDialog.on("edit-dialog:submit", async ({ payload }) => {
      if (!payload || !this.app || !this.reportView) {
        return;
      }

      await updateOeProjectAndRelatedActivities(
        payload,
        this.app?.getAppState().currentOEProject?.id
      );

      this.app?.navigate("../");
    });

    editDashboardDialog.on("edit-dialog:close", () => {
      this.app?.navigate("../");
    });
    const editView = this.app.createView({
      name: "Edit",
      layout: new RowLayout(),
    });
    const editRoute = this.route.createChildRoute({
      path: "edit",
      view: editView,
      isOverlayChild: true,
      isVisible: true,
    });

    editRoute.on("load", (payload) => {
      const viewName = payload.payload?.parameters?.viewname;

      switch (viewName) {
        case "awareness":
          this.app?.setAppState({
            ...this.app?.getAppState(),
            editDialogData: {
              ...(this.app?.getAppState().currentOEProject ?? {
                id: 0,
                name: "",
                cimId: "",
                team: [],
                activities: [],
              }),
              dimension: "awareness",

              activitiesGroupedBySubdimension:
                transformActivitiesToActivitiesGroupedBySubdimesion(
                  this.getDimensionForViewname("awareness"),
                  this.app?.getAppState().currentOEProject
                ),
              commentOverallStatus:
                this.app?.getAppState().currentOEProject?.commentAwareness,
            },
          });
          break;
        case "governance":
          this.app?.setAppState({
            ...this.app?.getAppState(),
            editDialogData: {
              ...(this.app?.getAppState().currentOEProject ?? {
                id: 0,
                name: "",
                cimId: "",
                team: [],
                activities: [],
              }),
              dimension: "governance",
              activitiesGroupedBySubdimension:
                transformActivitiesToActivitiesGroupedBySubdimesion(
                  this.getDimensionForViewname("governance"),
                  this.app?.getAppState().currentOEProject
                ),
              commentOverallStatus:
                this.app?.getAppState().currentOEProject?.commentGovernance,
            },
          });
          break;
        case "technology":
          this.app?.setAppState({
            ...this.app?.getAppState(),
            editDialogData: {
              ...(this.app?.getAppState().currentOEProject ?? {
                id: 0,
                name: "",
                cimId: "",
                team: [],
                activities: [],
              }),
              dimension: "technology",
              activitiesGroupedBySubdimension:
                transformActivitiesToActivitiesGroupedBySubdimesion(
                  this.getDimensionForViewname("technology"),
                  this.app?.getAppState().currentOEProject
                ),
              commentOverallStatus:
                this.app?.getAppState().currentOEProject?.commentTechnology,
            },
          });
          break;
        case "processes":
          this.app?.setAppState({
            ...this.app?.getAppState(),
            editDialogData: {
              ...(this.app?.getAppState().currentOEProject ?? {
                id: 0,
                name: "",
                cimId: "",
                team: [],
                activities: [],
              }),
              dimension: "processes",
              activitiesGroupedBySubdimension:
                transformActivitiesToActivitiesGroupedBySubdimesion(
                  this.getDimensionForViewname("processes"),
                  this.app?.getAppState().currentOEProject
                ),
              commentOverallStatus:
                this.app?.getAppState().currentOEProject?.commentProcesses,
            },
          });
          break;
      }
    });

    const editDialog = editView.addComponent({
      component: EditDialog,
      layoutProps: { row: 2 },
    });

    editDialog.mapState((state) => ({
      title: `Update - ${this.getDimensionForViewname(
        state.editDialogData?.dimension ?? "awareness"
      )}`,
      data: state.editDialogData ?? {
        dimension: "awareness",
        id: 0,
        cimId: "",
        projectId: "",
        name: "",
        startDate: "",
        endDate: "",
        team: [],
        activities: [],
      },
      reportingDate: formatDate(
        this.app?.getAppState().program?.reportingDate ?? ""
      ),
      isLoading: false,
      isAdminWithEditRights:
        isUserAdmin(state.userProfile) && state.userProfile?.hasWriteRole,
    }));
    editDialog.on("edit-dialog:submit", async ({ payload }) => {
      if (!payload || !this.app || !this.reportView) {
        return;
      }

      await updateOeProjectAndRelatedActivities(
        payload,
        this.app?.getAppState().currentOEProject?.id
      );

      this.app?.navigate("../");
    });

    editDialog.on("edit-dialog:close", () => {
      this.app?.navigate("../");
    });
  }
}
